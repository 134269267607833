import './Modal.css';

const CommonModalBox = ({ isOpen, onClose, title, children, buttons, width, disableClose = true }: any) => {
	if (!isOpen) return null;

	return (
		<div className="modal-overlay" onClick={disableClose ? onClose : undefined}>
			<div className={`modal-content modal-width-${width}`} onClick={(e) => e.stopPropagation()}>
				<div tabIndex={0} style={{ display: 'flex', height: '25px' }}>
					<button onClick={onClose} className="modal-close-button" style={{ marginLeft: 'auto' }}>
						<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none"><path fill="currentColor" d="m17.5.793-8 8-8-8-.707.707 8 8-8 8 .707.707 8-8 8 8 .707-.707-8-8 8-8L17.5.793Z"></path></svg>
					</button>
				</div>

				<div className="muse-modal-dialog-header">
					<h3 className='muse-headline-heading muse-mt-0'>{title}</h3>
				</div>

				<div className="muse-modal-dialog-content-slot muse-mt-40">
					{children}
				</div>

				<div style={{ textAlign: 'end' }} className='muse-mt-40 muse-mb-30'>
					{buttons}
				</div>
			</div>
		</div>
	);
};

export default CommonModalBox;