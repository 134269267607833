import axios from 'axios';
const savedAccessToken = sessionStorage.getItem('accessToken');

export const getSplunkKey = () => {
  return 'b959f9fc-dc49-4cb5-bd9e-710945652498';
};
export const getSplunkUrl = () => {
  return `https://web-help.cisco.com/splunk`;
};

export const apiUrl = () => {
  if (
    window.location.origin === 'https://web-help-dev.cisco.com' ||
    window.location.origin === 'http://localhost:3000'
  ) {
    return 'https://web-help-api-dev.cisco.com/';
  }
  if (window.location.origin === 'https://web-help-stg.cisco.com') {
    return 'https://web-help-api-stage.cisco.com/';
  } else if (window.location.origin === 'https://web-help.cisco.com') {
    return 'https://web-help.cisco.com/api/';
  }
};

export const getFaqs = () => {
  return axios.get(`${apiUrl()}faq`, {
    headers: {
      Authorization: `Bearer ${savedAccessToken}`,
    },
  });
};

export default async function getSearchedFaqs(name?: string) {
  //No serach of FAQ from here, will discard soon
  if (name) {
    return await axios.get(`${apiUrl()}faq?search=${name}`, {
      headers: {
        Authorization: `Bearer ${savedAccessToken}`,
      },
    });
  }
}

export const getLoginUrl = (param?: any) => {
  return axios.get(`${apiUrl()}okta/login?refer=${param}`);
};

export const getPopularFaqs = () => {
  return axios.get(`${apiUrl()}popular_faqs`, {
    headers: {
      Authorization: `Bearer ${savedAccessToken}`,
    },
  });
};

export const sendEnqueryEmail = (values: any, captchaToken: any, captchaVersion: any, isauthorized: any) => {
 

  return axios.post(
    `${apiUrl()}send_email?firstname=${values.firstName}&lastname=${
      values.lastName
    }&email_address=${values.email}&issue_category=${
      values.issueCategory
    }&issue_subcategory=${values.issueSubCategory}&description=${
      values.description
    }&isauthorized=${isauthorizedFn(isauthorized)}&captcha_token=${captchaToken}&captcha_version=${captchaVersion}`
  );
};

const isauthorizedFn = (isauthorized: any): boolean => {
  let userIsLoggedIn = sessionStorage.getItem('accessToken');
  // If the user is logged in, check if they are verified user
  if (userIsLoggedIn && isauthorized !== 'verified') {
    return false;
  }
  
  // If not logged in, allow access only if 'verified'
  return !!userIsLoggedIn || isauthorized === 'verified';
};

export const validateAccessToken = () => {
  let savedAccessToken = sessionStorage.getItem('accessToken');
  if (savedAccessToken) {
    return axios.get(`${apiUrl()}okta/token_validate`, {
      headers: {
        Authorization: `Bearer ${savedAccessToken}`,
      },
    });
  } else {
    return 'NO_ACCESS_TOKEN';
  }
};

export const updateFaqCount = (qId: any) => {
  if (qId) {
    return axios.put(`${apiUrl()}update_faq_count/${qId}`);
  }
};

export const logoutPortal = (email: any) => {
  let userIsLoggedIn = sessionStorage.getItem('accessToken');
  if (email) {
    return axios.get(`${apiUrl()}logout?email_id=${email}`, {
      headers: {
        Authorization: `Bearer ${userIsLoggedIn}`,
      },
    });
  }
};

export const getSearched = async (name?: string): Promise<any> => {
  if (name) {
    return await axios.get(`${apiUrl()}search?query=${name}`, {
      headers: {
        Authorization: `Bearer ${savedAccessToken}`,
      },
    });
  }
};

export const verify_email = (params: any) => {
  if (params) {
    return axios.post(`${apiUrl()}verify_email?to_email=${params.email_address}&otp=${params.otp}`);
  }
};

export const authorize_email = (params: any) => {
  if (params) {
    return axios.post(`${apiUrl()}authorize_email?to_email=${params.email_address}`);
  }
};

export const getResources = () => {
  return axios.get(`${apiUrl()}resources`, {
    headers: {
      Authorization: `Bearer ${savedAccessToken}`,
    },
  });
};

export const update_resources_faq = (payload: any) => {
  if (payload) {
    return axios.post(`${apiUrl()}update_resources_faq`, payload, {
      headers: {
        Authorization: `Bearer ${savedAccessToken}`,
      }
    });
  }
};