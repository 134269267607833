import Enquiry from "./Enquiry";
import { useState } from 'react';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha,
} from 'react-google-recaptcha-v3';

export default function EnquiryForm(props: any) {
    const [captchaValue, setcaptchaValue] = useState<any>('');

    function onCpatchaChange(value: string | null): void {
        setcaptchaValue(value);
    }

    function handleRefreshCaptcha() {
        setcaptchaValue('');
    }

    return (
        <>
            {
                captchaValue !== '' ? <Enquiry captchaToken={captchaValue} refreshCaptcha={handleRefreshCaptcha}></Enquiry> : ''
            }
            <GoogleReCaptchaProvider
                container={{
                    // optional to render inside custom element
                    element: '',
                    parameters: {
                        badge: 'inline', // optional, default undefined
                        theme: 'light', // optional, default undefined
                    },
                }}
                useEnterprise={true}
                reCaptchaKey={
                    (
                        window.location.hostname.includes('localhost') ||
                        window.location.hostname.includes('stg') ||
                        window.location.hostname.includes('dev')) ?
                        process.env.REACT_APP_PUBLIC_CAPTCHA_KEY_V3_NPRD || '' :
                        process.env.REACT_APP_PUBLIC_CAPTCHA_KEY_V3_PROD || ''
                }
            >

                <GoogleReCaptcha
                    onVerify={(value) => {
                        if (!captchaValue) {
                            onCpatchaChange(value);
                        }
                    }}
                />
            </GoogleReCaptchaProvider>
        </>
    )
}
